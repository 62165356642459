.title {
  margin: 0 0 45px 0;
  position: relative;
  letter-spacing: 7.2px;
  font-weight: normal;
  font-size: 1.5rem;
  text-transform: uppercase;

  &.popup {
    padding: 10px;

    &::after {
      left: 10px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    width: 157px;
    height: 4px;
    background: transparent linear-gradient(92deg, #8CCF6A 0%, #8AF7B0 100%) 0% 0% no-repeat padding-box;
    border-radius: 23px;
  }
}

.admin-title {
  margin: 0 0 45px 0;
  font-weight: 500;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.admin-subtitle {
  &.top {
    margin-top: 10px;
  }

  margin: 0 0 35px 0;
  font-weight: 500;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
