.find-user-dropdown {
  padding: 15px;
  background: darken(#f4f6fc, 2%);
  border-radius: 0 0 24px 24px;
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.05);

  input {
    background: white;
    padding: 10px;
    padding: 6px 16px;
  }

  ul li {
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.45);
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }

    &:last-child {
      border: 0;
      padding-bottom: 10px;
    }
  }
}
