// Basic custom grid system
.hide-desktop {
  display: none;
}

.hide-mobile {
  display: block;
}

.row {
  display: flex;
  width: 100%;
  max-width: $grid-max-width;
  margin: 0 auto;
  padding: 20px;
  align-items: flex-start;
  flex-flow: wrap;

  &.no-wrap {
    flex-wrap: nowrap;
  }

  &.no-padding {
    padding: 0;
  }

  &.center-cols {
    justify-content: center !important;
  }

  &.right-cols {
    justify-content: flex-end !important;
  }

  .col,
  [class^="col-"] {
    min-width: 0;
    flex-grow: 0;

    &.no-margin {
      margin-bottom: 0 !important;
    }
  }

  &.gutter {
    justify-content: space-between;
    grid-gap: 20px 20px;

    [class^="col-"] {
      margin-bottom: 20px;
    }

    .col {
      &-11 {
        flex-basis: calc(91% - 10px);
      }
      &-10 {
        flex-basis: calc(83% - 10px);
      }
      &-9 {
        flex-basis: calc(75% - 10px);
      }
      &-8 {
        flex-basis: calc(66% - 10px);
      }
      &-7 {
        flex-basis: calc(58% - 10px);
      }
      &-6 {
        flex-basis: calc(50% - 10px);
      }
      &-5 {
        flex-basis: calc(41% - 10px);
      }
      &-4 {
        flex-basis: calc(33% - 10px);
      }
      &-3 {
        flex-basis: calc(25% - 10px);
      }
      &-2 {
        flex-basis: calc(16% - 10px);
      }
      &-1 {
        flex-basis: calc(8% - 10px);
      }
    }
  }

  &.no-gutter [class^="col-"] {
    margin-bottom: 0;
  }

  .col,
  &.no-gutter .col {
    flex: auto;

    // column sizes
    &-12 {
      flex-basis: 100%;
    }
    &-11 {
      flex-basis: 91%;
    }
    &-10 {
      flex-basis: 83%;
    }
    &-9 {
      flex-basis: 75%;
    }
    &-8 {
      flex-basis: 66%;
    }
    &-7 {
      flex-basis: 58%;
    }
    &-6 {
      flex-basis: 50%;
    }
    &-5 {
      flex-basis: 41%;
    }
    &-4 {
      flex-basis: 33%;
    }
    &-3 {
      flex-basis: 25%;
    }
    &-2 {
      flex-basis: 16%;
    }
    &-1 {
      flex-basis: 8%;
    }
  }
}

@media screen and (max-width: $mobile-break) {
  .hide-mobile {
    display: none !important;
  }

  .hide-desktop {
    display: block;
  }

  .row [class^="col-"] {
    flex-basis: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 20px;
  }
}
