.payment-form {
  #st-form__submit {
    display: inline-block;
    background: transparent linear-gradient(180deg, #8CCF6A 0%, #8AF7B0 100%) 0% 0% no-repeat padding-box;
    color: white;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 22.5px;
    padding: 7.5px 20px;
    border-radius: 100px;
    border: 0;
    outline: 0;
    cursor: pointer;
  }

  .checkbox label {
    color: #aaa;
  }

  iframe {
    border-radius: 25px;
    border-style: none;
    width: 100%;
  }
}

.select-payment-method {
  .select-card {
    input {
      appearance: none;
    }

    label {
      cursor: pointer;
      margin: auto 0 auto 10px;
    }

    &.selected, &:hover {
      border-color: $accent-green;
      cursor: pointer;
    }

    display: flex;
    border: 2px solid $background-color;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 10px;
  }
}

.redirect-link {
  &:hover {
    color: $accent-green;
  }

  cursor: pointer;
  text-align: center;
  text-decoration: underline;
}

.payment-form-iframe {
  width: calc(100% + 20px);
  height: 500px;
  margin: -10px;
}

.cart-items-address-title {
  margin: 0 0 30px 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
}

.checkout-summary {
  .checkout-summary-header {
    display: flex;
    justify-content: space-between;

    h3 {
      margin-bottom: 25px;
    }

    a.edit-btn {
      color: $accent-default;
      font-size: 0.9rem;
      font-weight: bold;
      text-decoration: none;

      svg {
        display: inline-block;
        vertical-align: bottom;
        width: 15px;
        height: 15px;
        margin-right: 2.5px;
      }
    }
  }

  .checkout-summary-totals {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 20px;

    .summary-subtotal,
    .summary-total {
      display: flex;
      justify-content: space-between;
    }

    .summary-subtotal {
      font-size: 0.85rem;
      margin-bottom: 20px;
    }

    .summary-total .summary-amount {
      color: $accent-default;
      font-weight: bold;
      font-size: 1.2rem;
    }
  }
}
