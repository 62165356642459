$font-stack: "Roboto", Arial, Helvetica, sans-serif;

$grid-max-width: 1400px;
$mobile-break: 1110px;
$gutter-width: 20px;

$background-color: #1b1c31;
$background-secondary-color: #262744;
$background-tertiary-color: #484a69;
$admin-background-color: #f4f6fc;

$accent-default: #8CCF6A;
$accent-red: #e1085f;
$accent-yellow: goldenrod;
$accent-green: #2fde80;

$text-color: #fff;
$text-light-color: #5c7392;
$link-color: #fff;
$link-hover-color: #8AF7B0;

$input-background-color: #1b1c31;
