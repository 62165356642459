.faqs {
  .faq {
    &.open {
      .faq-question {
        background: transparent linear-gradient(180deg, #8CCF6A 0%, #8AF7B0 100%) 0% 0% no-repeat padding-box;
        border-radius: 13px 13px 0 0;
        color: #1B1C31;
      }

      .faq-answer {
        display: block;
        height: auto;
      }
    }

    .faq-question {
      background: $background-secondary-color 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      color: white;
      border-radius: 13px;
      padding: 20px;
      font-size: 1.2rem;
      cursor: pointer;
      user-select: none;

      svg {
        vertical-align: middle;
        float: right;
      }
    }

    .faq-answer {
      display: none;
      height: 0;
      background: $background-secondary-color 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 0 0 13px 13px;
      padding: 25px 20px;
      color: #A0CBF6;
      opacity: 0.58;
      line-height: 1.4rem;
    }
  }
}
