.main-navigation {
  position: relative;
}

.main-navigation-inner {
  // border: 1px solid red;
  padding: 20px 20px;
}

.global-logo {
  display: inline-block;
  text-decoration: none;
  text-indent: -99999px;
  background-image: url("https://massivecomps-static.s3.eu-west-2.amazonaws.com/img/massive-logo.gif");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 147px;
  height: 150px;
}

ul.main-navigation-links {
  text-align: right;

  li {
    display: inline-block;
    padding: 77.5px 30px;

    &:last-child {
      padding-right: 0;
    }

    .dropdown {
      &.open {
        display: block;
        z-index: 99999;
      }

      background: #151726;
      border-radius: 15px;
      display: none;
      overflow: hidden;
      position: absolute;
      margin: 15px 0 0 -55px;
      width: 255px;
    }

    &.subcategory {
      float: left;
      line-height: 1.4rem;
      padding: 15px;
      text-align: center;
      width: 100%;
    }
    
    .nav-chev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 20px;
    }

    a {
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 1px;

      &:hover {
        color: $link-hover-color;
      }
    }
  }

  .account-btn,
  .cart-btn {
    vertical-align: middle;
    display: inline-block;
    color: #1B1C31;
  }

  .cart-btn {
    width: 37px;
    height: 37px;
    padding: 0;
    position: relative;
    margin-left: 10px;

    svg {
      width: 17px;
      height: 17px;
      margin: 9px 6px;
    }

    span.cart-quantity {
      position: absolute;
      background: white;
      height: 18px;
      font-size: 0.7rem;
      font-weight: bold;
      line-height: 18px;
      color: black;
      padding: 0 6px;
      border-radius: 50px;
      text-align: center;
      top: -6px;
      right: -8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    }
  }
}

.global-trustpilot-banner {
  background: rgb(21, 23, 38);

  .col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.persistent-message {
  width: 100%;
  background-color: lighten($background-color, 20%);

  .col {
    display: flex;
    justify-content: space-between;
  }

  .message-left,
  .message-right {
    display: flex;
    align-items: center;
  }

  svg,
  span {
    vertical-align: top;
    margin-top: 4px;
  }

  svg {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
  }

  span {
    margin-left: 15px;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.75rem;
  }

  button.resend-email {
    flex-shrink: 0;
    font-size: 0.8rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.5rem !important;
    padding: 5px 15px;
    border-radius: 100px;
    border: 0;
    outline: 0;
    cursor: pointer;
  }

  button.close-btn {
    margin-left: 15px;
    background: none;
    border: 0;
    outline: 0;
    color: white;
    cursor: pointer;
  }
}

.flash-message {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 50%;
  width: 300px;
  transform: translateX(-50%);
  border-radius: 13px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 20px;
  color: #111;
  background: $background-color;
  color: #fff;
  transition: 0.15s ease-in-out;
  z-index: 9999999999;
  cursor: pointer;

  &.error {
    background: $accent-red;
  }

  &.warning {
    background: $accent-yellow;
  }

  &.success {
    background: $accent-green;
  }

  svg {
    display: inline-block;
  }

  span {
    display: inline-block;
    margin-left: 10px;
    margin-top: 3px;
    line-height: 1.3rem;
  }

  .close-icon {
    position: absolute;
    top: 7px;
    right: 10px;
  }
}

// Main content container
.app-main-content {
  // border: 1px solid blue;
  // margin-top: 30px;
  overflow: hidden;
}

.app-main-footer {
  text-align: center;
  // opacity: 0.3;
  padding: 50px 0;
  font-size: 0.9rem;
  color: #777;

  .footer-logos {
    .footer-logo {
      width: 170px;
      height: 170px;
      background: url(/img/massive-footer-logo.png) no-repeat;
      background-size: cover;
      margin: auto 30px auto auto;
    }
  
    .footer-logo-fibonatix {
      width: 200px;
      height: 123px;
      background: url("/img/fibonatix.png") no-repeat;
      background-size: contain;
      margin: auto auto auto 0;
    }

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 30px;
  }

  .links {
    margin-top: 5px;
  }

  .social-links {
    margin: 20px 0;

    .social-link {
      margin: 8px;
    }
  }
}

.global-cookie-notice {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(calc(-50% - 20px));
  width: calc(100% - 40px);
  max-width: 800px;
  margin: 20px;
  border-radius: 13px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 20px 40px 20px 65px;
  line-height: 1.5rem;
  color: #111;
  background: white;
  background-image: url("/img/cookies-graphic.svg");
  background-repeat: no-repeat;
  background-position: -20px;
  background-size: 70px;
  color: #222;
  transition: 0.15s ease-in-out;
  z-index: 9999999999;
  cursor: pointer;

  svg {
    display: inline-block;
  }

  span {
    display: inline-block;
    margin-left: 10px;
    margin-top: 3px;
    line-height: 1.3rem;
  }

  .close-icon {
    position: absolute;
    background: none;
    outline: 0;
    border: 0;
    padding: 10px;
    cursor: pointer;
    top: 0;
    right: 0;
  }
}

.global-promo-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  .global-promo-popup-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999;
  }

  .global-promo-popup-modal {
    position: relative;
    width: 100%;
    max-width: 1100px;
    height: auto;
    min-height: 600px;
    background: transparent;
    box-shadow: 0px 0px 46px #00000081;
    z-index: 9999;
    border-radius: 28px;
    margin: 20px;

    .global-promo-popup-closebtn {
      position: absolute;
      right: -15px;
      top: -15px;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      font-weight: 600;
      background: transparent linear-gradient(180deg, #8CCF6A 0%, #8AF7B0 100%) 0% 0% no-repeat padding-box;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      border: 0;
      color: white;

      svg {
        display: inline-block;
        width: 20px;
        height: 20px;
      }
    }

    .global-promo-popup-upsell {
      background-color: $background-secondary-color;
      border-radius: 15px;
      padding: 20px;
      width: 100%;
    }

    .global-promo-popup-upsell-continue {
      margin: 0 auto;

      .do-not-show-again {
        display: block;
        margin: 10px auto;
        width: fit-content;
      }
    }

    .global-promo-popup-img {
      width: 100%;
      background-size: 100% auto;
      background-position: 50% 50%;
      margin: 0;
      display: block;
    }
  }
}

@media screen and (min-width: $mobile-break + 1) {
  ul.main-navigation-links {
    display: block !important;
  }
}

@media screen and (max-width: $mobile-break) {
  .persistent-message {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999999;
    padding: 0px;

    .col {
      flex-direction: column;
    }

    .message-left {
      padding-right: 15px;
    }

    .message-right {
      justify-content: center;
      margin-top: 20px;
    }

    .close-btn {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  .main-navigation-inner {
    height: 100px;
  }

  .global-logo {
    margin: 0;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 168px;
    height: 89px;
  }

  .global-menu {
    position: absolute;
    left: 20px;
    top: 40px;
    color: white;
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;

    svg {
      width: 35px;
      height: 35px;
    }

    span.cart-quantity {
      position: absolute;
      background: white;
      height: 18px;
      font-size: 0.7rem;
      font-weight: bold;
      line-height: 18px;
      color: black;
      padding: 0 6px;
      border-radius: 50px;
      text-align: center;
      top: -6px;
      right: -8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    }
  }

  .global-account-btn {
    position: absolute;
    right: 20px;
    top: 40px;
    width: 35px;
    height: 35px;
    padding: 6px;
  }

  .main-navigation-links {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 110px;
    left: 0;
    background: $background-color;
    z-index: 9999999;
    text-align: left !important;
    overflow: hidden;
    transition: height 0.2s ease-in-out;

    li {
      display: block !important;
      padding: 20px 30px !important;

      a {
        font-size: 1.25rem;
      }
    }
  }
}
