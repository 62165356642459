.winner-card {
  position: relative;
  background: #262744;
  border-radius: 13px;
  height: auto;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  text-decoration: none;
  background-size: cover;
  background-position: 50% 50%;
  box-shadow: 0px 3px 6px #00000029;
  overflow: hidden;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.69) 0px 3px 8px;
    transition: 0.5s;
  }

  .winner-details {
    margin-top: 25px;
    padding: 0 10px 0 10px;
  }
}

.winner-ticket {
  .ticket-number {
    color: #1b1c31;
    position: relative;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
  }

  margin: 10px auto 5px auto;
  width: 150px;
  height: 30px;
  background-color: white;
  border-radius: 25px;
}

.winner-name {
  text-align: center;
  width: 100%;
  margin: 15px auto 0 auto;
  text-transform: uppercase;
  letter-spacing: 6.5px;
  font-weight: 400;
  font-size: 22px;
}

.draw-date {
  margin: 10px 0 10px 0;
  letter-spacing: 4px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
}

.winner-prize {
  text-align: center;
  width: 100%;
  margin: 10px auto 5px auto;
  text-transform: uppercase;
  letter-spacing: 5.5px;
  font-weight: bold;
  font-size: 22px;
  padding: 0px 10px 0px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    text-overflow: unset;
    overflow: unset;
    white-space: normal;
  }
}

.winner-image {
  height: 300px;
  width: 100%;
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 30%;
}

.winners-text {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  margin-bottom: 50px;
  letter-spacing: 1px;
  line-height: 22px;
}

.verify-draw {
  margin-top: 15px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 10px;
  padding-bottom: 20px;
}

.winners-title {
  margin: 0 0 45px 0;
  position: relative;
  letter-spacing: 7.2px;
  font-weight: normal;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    margin-left: -155px;
    bottom: -15px;
    width: 157px;
    height: 4px;
    background: transparent linear-gradient(180deg, #8CCF6A 0%, #8AF7B0 100%) 0% 0% no-repeat padding-box;
    border-radius: 23px;
  }
}

@media screen and (max-width: 1000px) {
  .winner-image {
    background-position-x: 50% !important;
    background-position-y: 30% !important;
  }
}