.coupon-info-tooltip {
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  vertical-align: middle;
  margin-left: 5px;

  svg {
    width: 18px;
    height: 18px;
  }
}

.coupon-code-select-raffles {
  margin: -5px;

  .tag {
    display: inline-block;
    vertical-align: middle;
    background: darken($admin-background-color, 35%);
    color: white;
    padding: 7px 15px;
    border-radius: 100px;
    margin: 5px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 0.9rem;

    button {
      background: none;
      outline: 0;
      border: 0;
      color: #eee;
      margin-left: 5px;
      cursor: pointer;

      svg {
        margin-top: 1px;
        vertical-align: top;
        width: 13px;
        height: 13px;
      }
    }

    &.new {
      padding: 5px 10px;
      background: #f4f6fc;
      color: #333;
      cursor: pointer;

      svg {
        width: 15px;
        height: 15px;
        stroke-width: 3px;
      }
    }
  }

  .new-tag-container {
    display: inline-block;
    position: relative;
  }

  .new-tag-search {
    position: absolute;
    width: 300px;
    background: darken($admin-background-color, 5%);
    padding: 10px;
    margin: 0px 5px;
    border-radius: 15px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    z-index: 1;

    input {
      padding: 5px 15px;
    }

    .category-results {
      margin-top: 10px;
      max-height: 200px;
      overflow-y: scroll;

      div {
        width: 100%;
        display: block;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 500;
        text-transform: uppercase;
        padding: 10px 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        cursor: pointer;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }

        &:last-child {
          margin: 0;
          border: 0;
        }
      }

      .no-cats {
        color: rgba(0, 0, 0, 0.2);
        cursor: default;

        &:hover {
          background: inherit;
        }
      }
    }
  }
}

.coupon-expired {
  color: $accent-red;

  &:after {
    content: " (Expired)";
  }
}
