.all-raffles {
}
.all-raffles-header {
  .new-raffle {
    margin-right: 20px;
  }

  .btn-switcher {
    display: inline-block;
    position: relative;
    width: 180px;
    height: 37px;
    border: 3px solid $accent-default;
    padding: 0;
    line-height: 31px;
    background: $accent-default;
    border-radius: 23px;
    transition: 0.2s ease-in-out;
    text-align: center;

    &.active-one:before {
      left: 0;
      width: 85px;
      transition: 0.2s;
    }

    &.active-two:before {
      left: 78px;
      width: 96px;
      transition: 0.2s;
    }

    &:before {
      top: 0;
      border-radius: 23px;
      content: "";
      background: white;
      height: 31px;
      display: block;
      position: absolute;
    }

    button {
      color: white;
      border-radius: 23px;
      display: inline-block;
      padding: 7px 12px;
      margin-right: 0px;
      text-decoration: none;
      font-size: 0.9rem;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1px;
      z-index: 999999;
      position: relative;
      background: none;
      border: 0;
      outline: 0;
      cursor: pointer;

      &.active {
        color: $accent-default;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .search-bar {
    margin-left: 20px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 250px;
    height: 37px;
    border: 3px solid #8CCF6A;
    padding: 7px 15px;
    border-radius: 100px;
    background: white;

    input {
      position: absolute;
      top: 7px;
      left: 15px;
      width: 185px;
      padding: 0;
      border: 0;
      outline: 0;
      font-size: 1rem;

      &::placeholder {
        font-size: 0.9rem;
        font-weight: 500;
        color: $accent-default;
        text-transform: uppercase;
      }
    }

    button {
      position: absolute;
      top: -3px;
      right: -3px;
      width: 37px;
      height: 37px;
      border: 0;
      border-radius: 100%;
      background: $accent-default;
      color: white;
      padding: 9px;

      svg {
        width: 17px;
        height: 17px;
        stroke-width: 3px;
      }
    }
  }
}

.raffles-table {
  width: 100%;
  letter-spacing: 0.5px;
  background: white;
  border-radius: 13px;

  &.no-side-padding {
    tr td {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  thead {
    th {
      color: #aaa;
      padding: 10px;
      border-bottom: 1px solid #eee;
    }
  }

  tbody {
    tr:last-child td {
      border: 0;
    }

    td {
      text-align: center;
      padding: 10px 20px;
      border-bottom: 1px solid #eee;

      &.no-raffles {
        padding: 20px;
      }

      .status-dot {
        width: 13px;
        height: 13px;
        background: black;
        border-radius: 100%;

        &.active {
          background: $accent-green;
        }

        &.inactive {
          background: $accent-red;
        }
      }

      .delete-btn {
        display: inline-block;
        vertical-align: middle;
        background: none;
        border: 0;
        outline: 0;
        margin: 5px 0px 5px 15px;
        color: $accent-default;
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.raffles-paginate {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
