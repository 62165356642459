.sort-dropdown-container {
  position: relative;

  .sort-dropdown {
    position: absolute;
    top: 50px;
    right: 0;
    text-align: center;
    width: 200px;
    background: white;
    color: #222;
    border-radius: 13px;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2);
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      top: -9px;
      right: 20px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 9px 9px;
      border-color: transparent transparent white transparent;
    }

    li {
      display: block;
      width: 100%;
      padding: 15px 15px;
      border-bottom: 1px solid #ddd;
      font-size: 0.9rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;

      svg {
        width: 14px;
        height: 14px;
        position: absolute;
        vertical-align: top;
        left: 10px;
        stroke-width: 3px;
        color: $accent-green;
      }

      &:hover {
        background: #eee;

        &:first-child {
          border-radius: 13px 13px 0 0;
        }
        &:last-child {
          border-radius: 0 0 13px 13px;
        }
      }

      &:last-child {
        border: 0;
      }
    }
  }
}

#profile-description {
  max-width: 400px; 
  margin-top: 50px; 
  position:relative;
}
#profile-description .text {
/*   width: 660px;  */
  margin-bottom: 5px; 
  color: #777; 
  padding: 0 15px; 
  position:relative; 
  font-family: Arial; 
  font-size: 14px; 
  display: block;
}
#profile-description .show-more {
/*   width: 690px;  */
  color: #777; 
  position:relative; 
  font-size: 12px; 
  padding-top: 5px; 
  height: 20px; 
  text-align: center; 
  background: #f1f1f1; 
  cursor: pointer;
}
#profile-description .show-more:hover { 
    color: #1779dd;
}
#profile-description .show-more-height { 
  height: 65px; 
  overflow:hidden; 
}

.selector-sort-dropdown-container {
  position: relative;

  .sort-dropdown {
    position: absolute;
    top: 50px;
    right: 142px;
    width: 200px;
    background: white;
    color: #222;
    border-radius: 13px;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2);
    z-index: 1;

    &.noauth {
      right: 20px;
    }

    &.month {
      right: 0;
    }

    &.year {
      &:before {
        right: unset;
        left: 63px;
      }

      left: 0;
    }

    ul {
      height: fit-content;
      max-height: 300px;
      overflow-y: scroll;
    }

    &:before {
      content: "";
      position: absolute;
      top: -9px;
      right: 19px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 9px 9px;
      border-color: transparent transparent white transparent;
    }

    li {
      display: block;
      width: 100%;
      padding: 15px 15px;
      border-bottom: 1px solid #ddd;
      font-size: 0.9rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;

      svg {
        width: 14px;
        height: 14px;
        position: absolute;
        vertical-align: top;
        left: 10px;
        stroke-width: 3px;
        color: $accent-green;
      }

      &:hover {
        background: #eee;

        &:first-child {
          border-radius: 13px 13px 0 0;
        }
        &:last-child {
          border-radius: 0 0 13px 13px;
        }
      }

      &:last-child {
        border: 0;
      }
    }
  }
}

/*.raffle-card {
  display: block;
  position: relative;
  background: black;
  border-radius: 13px;
  padding: 20px;
  height: 190px;
  text-align: center;
  text-decoration: none;
  background-size: cover;
  background-position: 50% 50%;
  box-shadow: 0px 3px 6px #00000029;
  overflow: hidden;

  .raffle-sale-banner {
    background: transparent linear-gradient(105deg, #f8036d 0%, #ff313d 100%) 0% 0% no-repeat
      padding-box;
    position: absolute;
    transform: rotate(315deg);
    z-index: 1;
    margin-top: 2px;
    margin-left: -82px;
    padding: 10px 74px;
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  &:hover {
    color: inherit;

    .raffle-card-bg-shade {
      background: rgba(0, 0, 0, 0.2);
      transition: 0.5s;
    }
  }

  .raffle-card-bg-shade {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    transition: 0.135s ease-in-out;
  }

  .raffle-card-contents {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
  }

  p {
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin: 10px 0 15px 0;
    text-shadow: 0px 3px 5px #00000080;
  }

  div.price {
    display: inline-block;
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000026;
    border: 2px solid $accent-default;
    color: $accent-default;
    border-radius: 23px;
    opacity: 1;
    padding: 7px 15px;
    font-weight: 500;
    font-size: 1.1rem;
    letter-spacing: 1px;
  }
}
*/

.raffle-info {
  .raffle-sale-banner {
    background: transparent linear-gradient(105deg, #f8036d 0%, #ff313d 100%) 0% 0% no-repeat
      padding-box;
    position: absolute;
    transform: rotate(315deg);
    z-index: 1;
    margin-top: 30px;
    margin-left: -69px;
    padding: 13px 84px;
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 5px;
  }

  .image-slider-container,
  .image-livestream {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 350px;
    background: black;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 13px;
    background-size: cover;
    background-position: 50% 50%;

    button {
      position: absolute;
      width: 40px;
      height: 40px;
      border: 0;
      border-radius: 100%;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      cursor: pointer;
      outline: 0;

      svg {
        margin: 5px auto;
        width: 30px;
        height: 30px;
      }

      &:disabled {
        background: rgba(0, 0, 0, 0.15);
        color: rgba(255, 255, 255, 0.5);
        cursor: not-allowed;
      }
    }

    button.prev {
      bottom: 20px;
      right: 20px;
    }
    button.next {
      bottom: 20px;
      right: 70px;
    }
  }

  .raffle-image.has-info-box {
    .image-slider-container,
    .image-livestream {
      height: 390px;
    }
  }

  .raffle-specifics {
    padding: 0 10px;

    h1 {
      margin-top: 12.5px;
      font-size: 2rem;
      letter-spacing: 2px;
      font-weight: normal;
      text-transform: uppercase;
    }

    .price-btn {
      margin-top: 5px;
      display: inline-block;
      font-size: 1.3rem;
      padding: 10px 20px 10px 125px;
      position: relative;
      font-weight: bold;
      cursor: pointer;

      .quantity {
        background: white;
        color: #222;
        position: absolute;
        left: 7px;
        top: 6px;
        width: 105px;
        height: 30px;
        border-radius: 100px;
        line-height: 30px;
        font-size: 1rem;
        font-weight: bold;
      }
    }

    .live-draw-countdown {
      margin-top: 30px;

      h3 {
        font-size: 1rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: normal;
      }

      .countdown-numbers {
        margin-top: 15px;
        margin-left: -10px;

        .countdown-number {
          display: inline-block;
          width: 70px;
          font-size: 2.5rem;
          font-weight: bold;
          text-align: center;
          color: $accent-default;

          span {
            display: block;
            font-size: 0.7rem;
            font-weight: normal;
            color: white;
            margin-top: 5px;
            text-transform: uppercase;
            letter-spacing: 1px;
          }
        }
      }
    }

    .tickets-remaining {
      margin-top: 30px;

      h3 {
        font-size: 1rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: normal;
      }
    }

    .guaranteed-winner-info-box {
      width: fit-content;
      margin-top: 20px;
      padding: 3px 12px;
      border-radius: 100px;
      background: rgb(246, 208, 76);
      color: #1b1c31;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.8rem;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: 0px;

      &.star svg {
        margin-left: unset;
        margin-right: 10px;
      }

      svg {
        width: 15px;
        height: 15px;
        margin-left: 10px;
      }
    }
  }
}

.tickets-remaining {
  h3 {
    display: inline-block;
    margin-bottom: 10px;
  }
}

.instant-wins {
  h2 {
    text-transform: uppercase;
  }

  .instant-wins-body {
    max-height: 200px;
    overflow-y: scroll;
    padding: 0 10px;
  }

  .instant-wins-title {
    column-gap: 10px;
    display: flex;
    padding: 10px;
  }

  background-color: $background-tertiary-color;
  border-radius: 25px;
  margin: 15px 0;
  max-width: 400px;
  padding: 10px 20px 20px 10px;
  width: fit-content;
}

.raffle-description {
  margin-bottom: 50px;
  padding-top: 30px;

  p {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }

  .showMoreLess {
    width: 150px;
    height: 40px;
    border: 1px solid #FFF;
    border-radius: 24px;
    font-size: 16px;
    letter-spacing: 2.4px;
    line-height: 38px;
    text-decoration: none !important;
    text-align: center;
    margin-top: 20px;
  }

  a {
    text-decoration: none !important;
  }

  svg {
    vertical-align: middle;
  }
}

.selector-btn {
  z-index: -1;
  width: 65px;
  height: 65px;
  margin: 5px;
  background-color: $background-color;
  border-radius: 13px;
  border: 0;
  outline: 0;
  color: $accent-default;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  user-select: none;

  &.selected {
    background-color: darken($background-color, 9%);
    color: $accent-default;
  }

  &:disabled {
    background-color: #1b1c316b;
    color: #8ccf6a7a;
    cursor: not-allowed;
  }
}

.ownerTooltip {
  display: inline-block;
  position: relative;
}

.ownerTooltip .bottom {
  min-width: 200px;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px;
  background-color: #8CCF6A;
  border-radius: 10px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0,0,0,0.5);
  display: none;

  p {
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    letter-spacing: 2.85px;
    color: #1b1c31;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.ownerTooltip:hover .bottom {
  display: block;
}

.ownerTooltip .bottom i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.ownerTooltip .bottom i::after {
  content:'';
  opacity: 1!important;
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%,50%) rotate(45deg);
  background-color: #8CCF6A;
  box-shadow: 0 1px 8px rgba(0,0,0,0.5);
}


.lucky-dip-container {
  .free-entry {
    float: left;
    line-height: 37px;
  }
}

.lucky-dip-btn {
  float: right;
}

.add-to-cart-btn-container {
  background: #1b1c31;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 30px;
}

.sticky-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99999;
} 

.add-to-cart-light-text {
  color: #bbb;
  margin-bottom: 15px;
}
.add-to-cart-login {
  margin-top: 15px;
  color: #bbb;
}

.add-to-cart-btn {
  margin: 0 auto 0 auto;
  font-size: 1.05rem;
  padding: 10px 65px 10px 20px;
  position: relative;

  .quantity {
    background: white;
    color: #222;
    position: absolute;
    right: 7px;
    top: 6px;
    width: 45px;
    height: 30px;
    border-radius: 100px;
    line-height: 30px;
    font-size: 1rem;
    font-weight: bold;
  }
}

.lucky-dip {
  font-size: 1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: normal;
  text-decoration: none;
}

.raffle-ticket-selector-header {
  position: relative;

  .selector-btn {
    background: $background-secondary-color;
    border-radius: 13px 13px 0 0;
    height: 57px;
    margin: 0 5px;

    &.selected {
      background: transparent linear-gradient(180deg, #8CCF6A 0%, #8AF7B0 100%) 0% 0% no-repeat padding-box;
      color: white;
    }
  }
}

.lucky-dip-bubble {
  background: transparent linear-gradient(180deg, #8CCF6A 0%, #8AF7B0 100%) 0% 0% no-repeat padding-box;
  width: 50px;
  height: 30px;
  text-align: center;
  border-radius: 30px;
  top: -10px;
  cursor: pointer;
}

.lucky-dip-bubble-text {
  color: black;
  text-align: center;
  padding: 5px;
}

.lucky-dip-slider-track.lucky-dip-slider-track-0 {
  background: transparent linear-gradient(180deg, #8CCF6A 0%, #8AF7B0 100%) 0% 0% no-repeat padding-box;
  height: 100%;
  border-radius: 5px;
}

.lucky-dip-slider {
  height: 10px;
  border-radius: 5px;
  width: 100%;
  margin: 10px auto;
  background-color: #1B1C31;
}

.raffle-ticket-selector-main {
  background: $background-secondary-color;
  padding: 0;
  // box-shadow: 0px 0px 8px #00000030;
}

.ticket-selector-letters {
  margin: 0 auto -20px;
  overflow-x: scroll;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
}

.ticket-selector-numbers-container {
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
}

.instant-win-ticket {
  .ticket-number {
    color: #1b1c31;
    position: relative;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
  }

  display: flex;
  justify-content: center;
  flex-direction: column;
  height: auto;
  padding: 10px;
  background-color: white;
  border-radius: 25px;
  margin-bottom: 10px;

  @media screen and (max-width: $mobile-break - 1) {
    min-width: 150px;
    padding: 10px 15px;
    width: fit-content;
  }
}

.instant-win-winner {
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: $mobile-break - 1) {
    text-align: left;
  }
}

.ticket-selector-numbers {
  padding: 20px 0;
}

.raffle-lucky-dip {
  margin: 50px auto;
  max-width: 600px;
  padding: 20px;
  text-align: center;

  h2 {
    font-size: 1.4rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 20px;
  }

  label.raffle-selectans {
    margin: 30px 0 20px 0;
  }

  .raffle-answer {
    padding-top: 10px;

    input {
      display: inline-block;
    }

    label {
      font-size: 1rem;
      display: inline-block;
      margin-left: 10px;
      color: white;
    }
  }

  button {
    margin-top: 20px;
  }
}

.raffle-answer-question {
  position: fixed;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  margin: 50px auto;
  max-width: 600px;
  width: 100%;
  background: $background-secondary-color;
  box-shadow: 0 0 100px rgba(#fff, 0.2);
  border-radius: 10px;
  padding: 40px 50px;
  text-align: center;

  .close-btn {
    position: absolute;
    top: 0;
    right: 20px;
    background: none;
    border: 0;
    appearance: none;
    color: white;
  }

  h2 {
    font-size: 1.4rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 20px;
  }

  label.raffle-selectans {
    margin: 30px 0 20px 0;
  }

  .raffle-answer {
    padding-top: 10px;

    input {
      display: inline-block;
    }

    label {
      font-size: 1rem;
      display: inline-block;
      margin-left: 10px;
      color: white;
    }
  }

  button {
    margin-top: 20px;
  }
}

.raffle-card {
  display: block;
  position: relative;
  background: #262744;
  border-radius: 13px;
  height: 445px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0px 3px 6px #00000029;

  &:hover {
    color: inherit;

    .raffle-card-bg-shade {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &.completed {
    height: unset;
    min-height: 350px;
  }

  &.upsell {
    box-shadow: unset;
  }

  .raffle-card-image {
    width: 100%;
    height: 204px;
    padding: 0px !important;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: 13px;
    display: block;
  }

  .raffle-card-bg-shade {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    transition: 0.135s ease-in-out;
  }

  .raffle-card-contents {
    padding: 20px;
  }

  h2 {
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  .raffle-name {
    font-size: 22.5px;
    width: 250px;
    text-align: left;
    margin-top: 8.5px;
    height: 80px;
    overflow: hidden;
    float: left;
  }

  .raffle-price {
    margin-top: 15px;
    padding: 1px 10px;
    //width: 130px;
    //height: 35px;
    border-radius: 37px;
    background: transparent linear-gradient(180deg, #8CCF6A 0%, #8AF7B0 100%) 0% 0% no-repeat padding-box;
    font-size: 20px;
    font-weight: 400;
    color: #1B1C31;
    text-align: center;
    line-height: 35px;
    float: right;
  }

  .row.raffle-timeleft {
    &.upsell {
      grid-gap: unset;
    }

    grid-gap: 0px 13px;
    padding: 15px 0 0 0;
  }

  @media screen and (max-width: $mobile-break) {
    height: 440px;

    .row .raffle-timeleft .col-3 {
      flex-basis: auto !important;
    }

    .row.raffle-timeleft {
      grid-gap: 0px 13px !important;
      padding: 15px 0 0 0 !important;
      justify-content: center !important;
      flex-flow: row !important;
    }

    .raffle-card-contents {
      padding: 15px !important;
    }

    .timeleft-box {
      border: 0px !important;
      padding: 0px !important;
      width: unset !important;
      font-size: 12px !important;
    }

    .raffle-name {
      width: 100% !important;
      text-align: center;
      height: 80px !important;
      overflow: hidden;
    }

    .raffle-price {
      width: 100% !important;
      margin-top: unset !important;
    }
  }

  .timeleft-box {
    width: 90px;
    padding: 11px;
    margin: 0 auto;
    border: 1px solid #8CCF6A;
    border-radius: 11px;
    text-transform: uppercase;
    text-align: center;

    .time {
      font-size: 20px;
      color: #8CCF6A;
      text-align: center;
    }
  }
}

button.btn.number-range-btn {
  margin-right: 20px;
  float: right;
}

@media screen and (max-width: $mobile-break) {  
  .image-slider-container,
  .image-livestream {
    height: 250px !important;
  }

  .instant-win-winner {
    line-height: unset !important;
    margin-bottom: unset !important;
  }

  .raffle-card.completed {
    min-height: 400px;
  }

  .ticket-selector-numbers-container {
    text-align: center;
  }

  .ticket-selector-letters {
    margin: 0 auto -40px;
    text-align: center;
  }

  .sort-dropdown-container {
    margin-bottom: 50px;

    .sort-dropdown {
      &:before {
        right: 37px;
      }
    }
  }
}
