.admin-raffle-page {
  h1 {
    display: inline-block;
  }

  .live-tag {
    position: relative;
    top: -5px;
    left: 10px;
    display: inline-block;
    background: #777777;
    color: white;
    padding: 5px 10px;
    border-radius: 100px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 0.9rem;

    &.toggleable {
      cursor: pointer;
      top: 15px;
      left: 25px;
    }

    &.live {
      background-color: $accent-green;
    }

    &.not-live {
      background-color: $accent-red;
    }
  }

  .countdown-numbers {
    display: inline-block;
    vertical-align: top;
    margin-top: -5px;
    margin-right: 20px;

    .countdown-number {
      display: inline-block;
      width: 60px;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      color: $accent-default;

      span {
        display: block;
        font-size: 0.7rem;
        font-weight: normal;
        color: #999;
        margin-top: -0px;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }

  .raffle-img {
    width: 150px;
    height: 90px;
    border-radius: 10px;
    background: $background-color;
    line-height: 110px;
    text-align: center;
    color: white;
    cursor: pointer;
    background-size: cover;
    background-position: 50% 50%;
    transition: 0.2s;

    &:hover svg {
      opacity: 1;
    }

    svg {
      width: 30px;
      height: 30px;
      opacity: 0.8;
      filter: drop-shadow(0px 0 8px rgba(0, 0, 0, 0.3));
    }
  }

  .raffle-info-item {
    position: relative;
    text-align: center;
    border-right: 1px solid #ddd;
    padding: 0 25px;
    height: 90px;
    font-weight: 500;

    &:last-child {
      border-right: 0;
    }

    a {
      text-decoration: none;
    }

    h3 {
      font-size: 0.9rem;
      color: #999;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-top: 12.5px;
      margin-bottom: 10px;

      &.pricing {
        display: flex;
        justify-content: space-between;
      }
    }

    p {
      font-size: 1.4rem;
      text-transform: uppercase;
      color: #222;
      margin-top: 15px;
    }
  }

  .tags {
    margin: -5px;

    .tag {
      display: inline-block;
      vertical-align: middle;
      background: darken($admin-background-color, 35%);
      color: white;
      padding: 7px 15px;
      border-radius: 100px;
      margin: 5px;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1px;
      font-weight: 500;
      font-size: 0.9rem;

      button {
        background: none;
        outline: 0;
        border: 0;
        color: #eee;
        margin-left: 5px;
        cursor: pointer;

        svg {
          margin-top: 1px;
          vertical-align: top;
          width: 13px;
          height: 13px;
        }
      }

      &.new {
        padding: 5px 10px;
        background: #f4f6fc;
        color: #333;
        cursor: pointer;

        svg {
          width: 15px;
          height: 15px;
          stroke-width: 3px;
        }
      }
    }

    .new-tag-container {
      display: inline-block;
      position: relative;
    }

    .new-tag-search {
      position: absolute;
      width: 300px;
      background: darken($admin-background-color, 5%);
      padding: 10px;
      margin: 0px 5px;
      border-radius: 15px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);

      input {
        padding: 5px 15px;
      }

      .category-results {
        margin-top: 10px;
        max-height: 200px;
        overflow-y: scroll;

        div {
          width: 100%;
          display: block;
          color: rgba(0, 0, 0, 0.6);
          font-weight: 500;
          text-transform: uppercase;
          padding: 10px 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          cursor: pointer;

          &:hover {
            background: rgba(255, 255, 255, 0.1);
          }

          &:last-child {
            margin: 0;
            border: 0;
          }
        }

        .no-cats {
          color: rgba(0, 0, 0, 0.2);
          cursor: default;

          &:hover {
            background: inherit;
          }
        }
      }
    }
  }
}
