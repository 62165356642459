.modal-container {
  &.winner {
    overflow: hidden;
  }

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 50px;
}

.modal-bg {
  &.winner {
    background: rgba(211, 211, 211, 0.25);
    z-index: 9999999;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}

.modal {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  border-radius: 13px;
  padding: 30px;
  width: 600px;
  z-index: 99999999;

  &.winner {
    background: $accent-green;

    @media screen and (max-width: $mobile-break - 1) {
      width: 75vw;
    }

    button.close-btn {
      svg {
        width: 25px;
        height: 25px;
        flex-shrink: 0;
      }

      background: none;
      border: 0;
      position: absolute;
      outline: 0;
      color: $background-color;
      cursor: pointer;
      top: 15px;
      right: 15px;
    }

    h1 {
      color: $background-color;
      font-size: 40px;
      text-align: center;
      line-height: 44px;
    }

    .info {
      color: $background-color;
      text-align: center;
      font-size: 16px;
      font-weight: bold;

      &.more {
        margin-bottom: 15px;
      }
    }

    .prize {
      background-color: white;
      color: $background-color;
      font-size: 24px;
      font-weight: bold;
      margin: auto;
      width: fit-content;
      padding: 10px;
      border-radius: 15px;
      margin-bottom: 10px;
    }
  }

  h2 {
    margin: 0 0 20px 0;
    font-size: 1.15rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  h3 {
    margin: 0 0 20px 0;
    font-size: 0.95rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}
