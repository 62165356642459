.md-text {
  &.small {
    margin-bottom: 10px;
    max-height: 65px;
    overflow: hidden;
  }

  ol,
  ul,
  li {
    list-style-position: inside;
    list-style-type: disc !important;
  }

  ol {
    padding: 0 25px;
  }

  ol li {
    padding-left: 0px;
  }

  p,
  ol,
  li,
  ul {
    //line-height: 1.8rem;
    margin-bottom: 20px;
    color: inherit;
    white-space: pre-wrap;
  }
}
