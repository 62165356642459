@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import "../node_modules/normalize.css/normalize.css";

@import "./variables";

@import "./styles/general";
@import "./styles/grid";
@import "./styles/buttons";
@import "./styles/inputs";
@import "./styles/slate";
@import "./styles/titles";
@import "./styles/modals";
@import "./styles/markdown";

@import "./components/includes/includes";
@import "./components/layouts/main-layout";
@import "./components/layouts/admin-layout";
@import "./components/auth/auth";
@import "./components/pages/pages";
@import "./components/admin-pages/pages";

.site-banner {
  width: 100%;
  padding: 10px;
  background: crimson;
  color: white;
  text-align: center;
}
