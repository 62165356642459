.btn {
  display: inline-block;
  background: transparent linear-gradient(180deg, #8CCF6A 0%, #8AF7B0 100%) 0% 0% no-repeat padding-box;
  color: #1B1C31;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.5rem !important;
  padding: 7.5px 20px;
  border-radius: 100px;
  border: 0;
  outline: 0;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
    vertical-align: top;
    margin-top: 2px;
  }

  &:hover {
    color: rgb(255, 255, 255) !important;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &.btn-white {
    background: white;
    color: #1B1C31;

    &:hover {
      color: $accent-default !important;
    }
  }

  &.btn-black {
    background: black;
    border: 2px solid $accent-default;
    color: $accent-default;
    font-weight: 500;

    &:hover {
      color: $accent-default !important;
    }
  }

  &.btn-large {
    padding: 12.5px 35px;
    font-size: 1.15rem;
    letter-spacing: 1.5px;
  }

  &.btn-small {
    padding: 4px 18px;
    font-size: 0.85rem;
    letter-spacing: 1px;
  }

  &.btn-tiny {
    padding: 1px 10px;
    font-size: 0.8rem !important;
  }

  &.btn-admin {
    background: $accent-default;
    color: white;
    font-size: 0.9rem;
    font-weight: 500;

    &.btn-paginate {
      width: 45px;
      padding: 10.2px 7px;

      svg {
        vertical-align: middle;
        width: 25px;
        height: 25px;
        margin-top: -0.5px;
      }
    }
  }
}

button {
  &.selected {
    background: black;
    color: white;
  }
}
