.tickets-bar-container {
  .bar {
    position: relative;
    width: 100%;
    height: 10px;
    background: white;
    border-radius: 50px;
    margin: 0 0 10px 0;
    overflow: hidden;

    .bar-fill {
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 10px;
      background: transparent linear-gradient(180deg, #8CCF6A 0%, #8AF7B0 100%) 0% 0% no-repeat padding-box;
      border-radius: 50px;
      animation: soldTickets ease 2.5s normal both;
      -moz-animation: soldTickets ease 2.5s normal both;
      -o-animation: soldTickets ease 2.5s normal both;
      -webkit-animation: soldTickets ease 2.5s normal both;
    }
  }

  .bar-indices {
    display: flex;
    justify-content: space-between;

    div {
      font-size: 15px;
      font-weight: 500;
    }

    .left, .right {
      opacity: 0.5;
      font-size: 0.9rem;
    }

    .tremaining {
      color: #8ccf6a;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

@keyframes soldTickets {
  0%   {width: 0}
  100% {width:var(--width);}
}

.promoted-raffle-card {
  display: block;
  position: relative;
  background: #262744;
  border-radius: 13px;
  height: 445px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0px 3px 6px #00000029;

  &.card-main {
    width: 100%;
    height: 400px;
    border: 1px solid $accent-default;

    h2 {
      font-size: 2rem;
      font-weight: 400;
      letter-spacing: 4px;
      text-transform: uppercase;
    }

    p {
      margin: 20px 0;
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  &:hover {
    color: inherit;

    .raffle-card-bg-shade {
      background: rgba(0, 0, 0, 0);
    }
  }

  .raffle-card-image {
    width: 100%;
    height: 204px;
    padding: 0px !important;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: 13px;
    display: block;
  }

  .raffle-card-bg-shade {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    transition: 0.135s ease-in-out;
  }

  .raffle-card-contents {
    padding: 20px;
  }

  h2 {
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  .raffle-name {
    font-size: 22.5px;
    width: 250px;
    text-align: left;
    margin-top: 8.5px;
    height: 80px;
    float: left;
    overflow: hidden;
  }

  .raffle-price {
    margin-top: 15px;
    padding: 1px 10px;
    border-radius: 37px;
    background: transparent linear-gradient(180deg, #8CCF6A 0%, #8AF7B0 100%) 0% 0% no-repeat padding-box;
    font-size: 20px;
    font-weight: 400;
    color: #1B1C31;
    text-align: center;
    line-height: 35px;
    float: right;
  }

  .row.raffle-timeleft {
    grid-gap: 0px 13px;
    padding: 15px 0 0 0;
    justify-content: center;
  }

  @media screen and (max-width: $mobile-break) {
    height: 440px;

    .row .raffle-timeleft .col-3 {
      flex-basis: auto !important;
    }

    .row.raffle-timeleft {
      grid-gap: 0px 13px !important;
      padding: 15px 0 0 0 !important;
      justify-content: center !important;
      flex-flow: row !important;
    }

    .raffle-card-contents {
      padding: 15px !important;
    }

    .timeleft-box {
      border: 0px !important;
      padding: 0px !important;
      width: unset !important;
      font-size: 12px !important;
    }

    .raffle-name {
      width: 100% !important;
      text-align: center;
      height: 80px;
      overflow: hidden;
    }

    .raffle-price {
      width: 100% !important;
      margin-top: unset !important;
    }
  }


  .timeleft-box {
    width: 90px;
    padding: 11px;
    border: 1px solid #8CCF6A;
    border-radius: 11px;
    text-transform: uppercase;
    text-align: center;

    .time {
      font-size: 20px;
      color: #8CCF6A;
      text-align: center;
    }
  }
}


.show-desktop {display: flex;}
.show-mobile {display: none;}

.row.no-padding.gutter.show-mobile {
  flex-flow: row;
  overflow-x: scroll;
  padding-bottom: 5px;
}

.row.no-padding.gutter.popular-mobile {
  flex-flow: row;
  overflow-x: scroll;
  padding-bottom: 5px;
}

@media screen and (max-width: $mobile-break) {
  .show-desktop {display: none;}
  .show-mobile {display: flex;}
}

.col.mobile-card {
  min-width: 40% !important;
}

.promoted-raffle-card-main {
  display: block;
  position: relative;
  background: black;
  border-radius: 13px;
  padding: 20px;
  height: 190px;
  text-align: center;
  text-decoration: none;
  background-size: cover;
  background-position: 50% 50%;
  box-shadow: 0px 3px 6px #00000029;

  &.card-main {
    width: 100%;
    height: 400px;
    border: 1px solid $accent-default;

    h2 {
      font-size: 2rem;
      font-weight: 400;
      letter-spacing: 4px;
      text-transform: uppercase;
    }

    p {
      margin: 20px 0;
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  &:hover {
    color: inherit;

    .raffle-card-bg-shade {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  .raffle-card-bg-shade {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    transition: 0.135s ease-in-out;
  }

  .raffle-card-contents {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
  }

  h2 {
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  p {
    margin: 15px 0;
    font-size: 0.85rem;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}



.cta-slab {
  padding: 60px 0 40px 0;
}

.cta-slab,
.cta-slate {
  background: $background-secondary-color;

  .cta-image {
    width: calc(100% - 25px);
    height: 350px;
    background-color: black;
    background-size: cover;
    background-position: 50% 40%;
    border-radius: 13px;
  }

  p {
    font-size: 1rem;
    line-height: 1.7rem;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 20px;
  }
}

.top-raffles {
  margin: 40px 0 20px 0;
}
.testimonials {
  margin-bottom: 40px;
  width: calc(100% + 20px);
}

.other-raffle-slider {
  .carousel-item {
    padding-right: 20px;
  }

  .carousel-dots {
    li button:before {
      color: white;
    }

    margin-top: 0;
  }

  .carousel-arrow {
    background: white;
    width: 32.5px;
    height: 32.5px;
    top: 50%;
    z-index: 99999;
    position: absolute;
    transform: translateY(-50%);

    &.carousel-prev {
      left: 0px;
    }
    
    &.carousel-next {
      right: 20px;
    }
  }

  .mobile {
    .carousel-item {
      padding-right: unset;
    }
  }

  .other-raffle {
    position: relative;
    width: 100%;
    height: 480px;
    text-align: center;
    border-radius: 13px;
    cursor: pointer;
  }

  margin-left: 10px;
}

.testimonial-slider {
  .carousel-item {
    padding-right: 20px;
  }

  .carousel-slide {
    outline: 0;
  }

  .carousel-dots {
    li button:before {
      color: white;
    }
  }

  .carousel-arrow {
    background: white;
    width: 40px;
    height: 40px;
    top: 50%;
    z-index: 99999;
    position: absolute;
    transform: translateY(-50%);

    &.carousel-prev {
      left: 20px;
    }
    &.carousel-next {
      right: 40px;
    }
  }

  .testimonial {
    position: relative;
    width: 100%;
    height: 350px;
    background: black;
    background-size: cover;
    background-position: 50% 40%;
    text-align: center;
    border-radius: 13px;
    box-shadow: 0px 3px 6px #00000029;
    cursor: default;

    .testimonial-shade {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background: rgba(0, 0, 0, 0.4);
    }

    .testimonial-inner {
      width: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    p {
      font-size: 1.2rem;
      line-height: 1.6rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 20px;
    }

    button {
      cursor: default;
    }
  }
}

.row.no-padding.gutter.stats-winners {
  text-align: center;
}

.row.no-padding.gutter.stats-winners .col-4 {
  padding: 50px;
}

.stats-winners-img {
  display: flex;
  justify-content: center;
  height: 100px;

  .winners {
    background: url("../../../img/stats-winners.png") no-repeat;
    background-size: contain;
    width: 90px;
    height: 90px;
    margin-left: -10px;
  }

  .prizes {
    background: url("../../../img/stats-prizes.png") no-repeat;
    background-size: contain;
    width: 100px;
    height: 100px;
  }

  .charity {
    background: url("../../../img/stats-chairty.png") no-repeat;
    background-size: contain;
    width: 90px;
    height: 90px;
    ;
  }
}

.stats-winners-title {
  font-size: 40px;
  letter-spacing: 15px;
  font-weight: 600;
}

.stats-winners-text {
  font-size: 24px;
  letter-spacing: 7.2px;
}


.how-it-works-video {
  width: 100%;
  padding: 0 30px 0 0;

  iframe {
    border-radius: 13px;
    overflow: hidden;
  }
}

@media screen and (max-width: $mobile-break) {
  .cta-image {
    width: 100% !important;
  }

  .how-it-works-video {
    padding: 0;

    iframe {
      height: 250px;
    }
  }

  .stats-winners {
    flex-wrap: nowrap;
  }

  .stats-winners .col-4 {
    padding: 25px !important;
  }

  .stats-winners-title {
    font-size: 20px !important;
    letter-spacing: 2.5px !important;
  }

  .stats-winners-img .winners {
    margin-left: 0 !important;
  }
  
  .stats-winners-text {
    font-size: 15px !important;
    letter-spacing: 2px !important;
  }
}
  

@media screen and (max-width: 532px) {
  .stats-winners {
    flex-wrap: wrap;
  }
}
