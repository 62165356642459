.user-account {
}

@media screen and (max-width: $mobile-break){
  .cart-item .cart-item-meta {
      flex-direction: column;
      align-items: flex-start;
      min-width: unset !important;
  }

  .cart-item .cart-item-pricing {
      flex-direction: column !important;
      margin-left: -20px !important;
  }

  .cart-item .countdown-numbers {
    max-width: unset !important;
  }

  .cart-item .cart-item-name {
    overflow: hidden !important;
  }
  
  .cart-item .countdown-numbers .countdown-number {
    font-size: 1.3em !important;
  }

}

.account-tag {
  display: block;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 4.8px;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 50px;
  margin-left: 5px;

  &.clickable {
    cursor: pointer;
  }

  &:hover {
    color: #8AF7B0;
  }

  svg {
    vertical-align: middle;
  }
}

.ticket-order-price {
  display: block;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 4.8px;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 50px;
  margin-left: 5px;
}

.ticket-order-seperator {
  width: 0px;
  height: 31px;
  border: 1px solid #4B558D;
  margin: 0 15px 0 15px;
}

.user-account-name {
  background: transparent linear-gradient(180deg, #8CCF6A 0%, #8AF7B0 100%) 0% 0% no-repeat padding-box;
  padding: 30px;
  color: #1B1C31;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 4px;
  text-transform: uppercase;
  border-radius: 13px 13px 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-account-promobal {
  position: relative;
  margin: 30px 20px 0 20px;
  border-radius: 7.5px;
  padding: 16px 40px 16px 16px;
  background: transparent linear-gradient(180deg, #8CCF6A 0%, #8AF7B0 100%) 0% 0% no-repeat padding-box;
  overflow: hidden;

  .promobal-icon {
    position: absolute;
    top: -25px;
    left: -35px;
    width: 150px;
    height: 150px;
    z-index: 0;
    opacity: 0.2;
    transform: rotate(-20deg);
  }

  .promobal-label {
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
  }

  .promobal-amount {
    position: relative;
    font-size: 1.6rem;
    margin-top: 5px;
    font-weight: 500;
    z-index: 99;
  }

  .promobal-history {
    display: inline-block;
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
    color: white;
    margin-left: 10px;

    svg {
      width: 17px;
      height: 17px;
    }
  }

  .promobal-add {
    position: absolute;
    top: 6px;
    right: 6px;
    background: rgba(255, 255, 255, 0.4);
    border: 0;
    line-height: 25px;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.2s;

    &.close {
      transform: rotate(45deg);
    }
  }

  .promobal-code-form {
    margin-top: 12px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding-top: 15px;

    label {
      color: white;
    }

    input {
      position: relative;
      font-size: 0.9rem;
      padding: 9px 15px;
      background: white;
      color: #333;
      letter-spacing: 0.5px;
    }
  }
}

.user-account-referrals {
  h2 {
    display: inline;
    margin: 0;
    text-transform: uppercase;
    line-height: 2.5rem;
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 4px;
  }

  .link-box {
    .code {
      font-size: 1.25rem;
      letter-spacing: 4px;
      text-transform: uppercase;
      text-align: center;
      text-decoration: underline;
      text-underline-offset: 2.5px;
    }

    display: inline;
    cursor: pointer;
    margin-left: 10px;
    background: #1b1c31;
    padding: 10px;
    border-radius: 7.5px;
  }
}

.user-account-nav {
  padding: 40px 0;

  ul li {
    margin-bottom: 30px;

    &:last-child {
      margin: 0;
    }

    a {
      font-size: 1.15rem;
      letter-spacing: 3px;
      text-transform: uppercase;
      padding-left: 30px;
      text-decoration: none;
      transition: border-left 0.2s ease-in-out;

      &.active {
        border-left: 5px solid $link-hover-color;
        padding-left: 25px;
      }

      &.admin-link {
        color: $accent-default;
        font-weight: bold;
      }
    }
  }
}

.user-account-main-header {
  margin-bottom: 30px;

  .back-btn {
    display: inline-block;
    vertical-align: top;
    margin: 5px 20px 0 0;
    opacity: 0.5;
  }

  h1 {
    margin: 0;
    text-transform: uppercase;
    line-height: 2.5rem;
    font-size: 1.5rem;
    font-weight: normal;
    letter-spacing: 4px;
  }

  // switcher element, kinda like iOS toggles
  .btn-switcher {
    display: inline-block;
    position: relative;
    padding: 5px;
    background: rgba(75, 92, 120, 0.23);
    border-radius: 23px;
    transition: 0.2s ease-in-out;

    &.account-history-sorter {
      &.active-one:before {
        width: 76px;
      }
      &.active-two:before {
        width: 86px;
        left: 78px;
      }
    }

    &.tickets-sorter {
      &.active-one:before {
        width: 140px;
      }
      &.active-two:before {
        width: 115px;
        left: 147px;
      }
      &.active-three:before {
        width: 112px;
        left: 265px;
        transition: 0.2s;
      }

      @media screen and (max-width: 463px) {
        button {
          display: block;
          width: 100%;
        }

        &.active-one:before, &.active-two:before, &.active-three:before {
          left: 50%;
          transform: translateX(-50%);
          margin: auto;
          width: 90%;
        }

        &.active-two:before {
          top: 31px;
        }

        &.active-three:before {
          top: 58px;
        }
      }
    }

    &.active-one:before {
      left: 5px;
      width: 50%;
      transition: 0.2s;
    }

    &.active-two:before {
      left: calc(50% - 5px);
      width: 50%;
      transition: 0.2s;
    }

    &:before {
      top: 5px;
      border-radius: 23px;
      content: "";
      background: white;
      height: 27px;
      display: block;
      position: absolute;
    }

    button {
      color: white;
      border-radius: 23px;
      display: inline-block;
      padding: 7px 12px;
      margin-right: 0px;
      text-decoration: none;
      font-size: 0.8rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      z-index: 999999;
      position: relative;
      background: none;
      border: 0;
      outline: 0;
      cursor: pointer;

      &.active {
        color: #1B1C31;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.edit-details-subtitle {
  margin: 20px 0 30px 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
}

.edit-details-same-address-checkbox {
  margin-bottom: 30px;

  label {
    margin-left: 10px;
  }
}

.account-settings {
  .settings-link {
    display: flex;
    width: 100%;
    text-decoration: none;
    background: $background-color;
    margin-bottom: 15px;
    padding: 20px;
    border-radius: 10px;
    transition: 0.05s;
    color: white;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: darken($background-color, 3%);
    }

    svg,
    span {
      display: inline-block;
    }

    svg {
      flex-shrink: 0;
      width: 25px;
      height: 25px;
      margin: 5px 20px 5px 5px;
    }

    span {
      h4 {
        margin-bottom: 5px;
        text-transform: uppercase;
        letter-spacing: 1px;

        .link-tag {
          font-size: 0.6rem;
          padding: 3px 8px;
          margin-left: 10px;
          display: inline-block;
          vertical-align: top;
          border-radius: 100px;

          &.on {
            background: $accent-green;
          }

          &.off {
            background: $accent-red;
          }
        }
      }

      p {
        color: $text-light-color;
        font-size: 0.9rem;
      }
    }
  }
}

.two-fac {
  .qrcode-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .qrcode-img img {
      width: 180px;
      height: 180px;
    }

    .qrcode-text {
      background: $background-color;
      padding: 15px;
      width: 180px;
      height: 180px;
      margin-left: 20px;
      color: #ddd;

      .fb-code {
        font-family: monospace;
        width: 100px;
        margin: 10px auto 0 auto;
        font-weight: bold;
      }
    }
  }
}

.pincode-input-container {
  margin: 20px 0;
  text-align: center;

  .pincode-input-text {
    background: $background-color !important;
    border: 0 !important;
    border-radius: 10px;
    font-size: 2rem !important;
    color: white;
    width: 60px !important;
    height: 60px !important;
    margin: 5px !important;
  }
}

.promotional-balance-history-table {
  width: 100%;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    th {
      text-transform: uppercase;
      font-size: 0.85rem;
      letter-spacing: 2px;
      color: $accent-default;
    }

    td,
    th {
      text-align: left;
      padding: 20px 10px;

      .trans-positive {
        font-weight: bold;
        color: $accent-green;

        &:before {
          content: "+";
        }
      }

      .trans-negative {
        font-weight: bold;
      }
    }
  }
}

.order-item-container {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.order-item {
  display: flex;
  justify-content: space-between;
  background: $background-color;
  border-radius: 10px;
  padding: 20px;
  transition: 0.15s ease-in-out;

  &.expanded {
    border-radius: 10px 10px 0 0;
  }

  .order-item-details {
    display: flex;
    flex-wrap: wrap;
    flex: 0.5;
    justify-content: space-between;

    .order-item-detail {
      .detail-header {
        font-size: 0.75rem;
        font-weight: bold;
        color: $accent-default;
        text-transform: uppercase;
        letter-spacing: 2px;
      }

      .detail-body {
        margin-top: 5px;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }

  .order-item-status-total {
    display: flex;
    flex: 0.5;
    flex-wrap: wrap;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    justify-content: flex-end;

    .order-item-total {
      margin-left: 15px;
    }

    .order-item-expcoll {
      flex-wrap: wrap;
      margin-left: 15px;
      background: none;
      color: white;
      outline: 0;
      border: 0;
      cursor: pointer;
    }
  }
}

.order-item-expanded-content {
  border-radius: 0 0 10px 10px;
  width: 100%;
  background: #161729;
  z-index: 1;
  transition: 0.2s ease-in-out;
  overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 0.9rem;
  overflow-y: scroll;

  .order-item-tickets {
    margin: 20px;

    .order-item-ticket {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .text-muted {
        margin: 0 10px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  .order-payment {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px;
  }

  .order-card-details {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  .order-breakdown {
    .breakdown-item {
      text-align: right;
      margin-bottom: 10px;

      &:last-child {
        margin: 0;
      }

      .breakdown-item-text {
        color: $accent-default;
        font-size: 0.6rem;
        margin-bottom: 5px;
      }

      .breakdown-item-amount {
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: $mobile-break) {
  .user-account-main-header {
    display: block;

    .col {
      display: block;
      text-align: center !important;
    }
  }

  .order-item-details > div {
    margin: 5px 0;
  }

  .order-item-status-total > div {
    margin: 5px 0;
    text-align: right;
  }

  .ticket-order-seperator {
    display: none;
  }

  .pincode-input-container .pincode-input-text {
    // background: $background-color !important;
    // border: 0 !important;
    // border-radius: 10px;
    font-size: 1.5rem !important;
    color: white;
    width: 55px !important;
    height: 55px !important;
    margin: 2px !important;
  }
}


